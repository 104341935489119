import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import _ from "lodash";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

import { prevPageUrl, nextPageUrl } from "../utils/common";
import { Layout } from "../components";
import { PrimarySection } from "../components/common";
import { MetaData } from "../components/common/meta";

/**
 * Topic page (/topics/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Topic = ({ data, location, pageContext }) => {
  const { currentPage, numPages } = pageContext;
  const tag = data.ghostTag;
  const posts = data.allGhostPost.edges;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const baseUrl = `/topics/${tag.slug}`;
  const prevPage = prevPageUrl(baseUrl, currentPage);
  const nextPage = nextPageUrl(baseUrl, currentPage);

  return (
    <>
      <MetaData data={data} location={location} type="series" />
      <Layout title={tag.name}>
        <div className="max-w-7xl mx-auto px-4 sm:px-8 mt-20 mb-32 space-y-8">
          <h1 className="uppercase">{tag.name}</h1>
          {tag.description && (
            <div className="text-gray-500">{tag.description}</div>
          )}
          {_.chunk(posts, 7).map((posts, i) => (
            <PrimarySection key={`posts-${i}`} posts={posts} isHome={false} />
          ))}
          <nav className="px-4 flex items-center justify-between sm:px-0">
            {!isFirst && (
              <div className="-mt-px w-0 flex-1 flex">
                <Link
                  to={prevPage}
                  className="pt-4 pr-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  <ArrowNarrowLeftIcon
                    className="mr-3 h-5 w-5"
                    aria-hidden="true"
                  />
                  Previous
                </Link>
              </div>
            )}
            {!isLast && (
              <div className="-mt-px w-0 flex-1 flex justify-end">
                <Link
                  to={nextPage}
                  className="pt-4 pl-1 inline-flex items-center text-lg font-medium text-gray-500 hover:text-gray-700"
                >
                  Next
                  <ArrowNarrowRightIcon
                    className="ml-3 h-5 w-5"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            )}
          </nav>
        </div>
      </Layout>
    </>
  );
};

Topic.propTypes = {
  data: PropTypes.shape({
    ghostTag: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
    allGhostPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Topic;

export const pageQuery = graphql`
  query GhostTopicQuery($slug: String!, $limit: Int!, $skip: Int!) {
    ghostTag(slug: { eq: $slug }) {
      ...GhostTagFields
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`;
